import { render, staticRenderFns } from "./SearchHelp.vue?vue&type=template&id=80c14d50&scoped=true"
import script from "./SearchHelp.vue?vue&type=script&lang=ts"
export * from "./SearchHelp.vue?vue&type=script&lang=ts"
import style0 from "./SearchHelp.vue?vue&type=style&index=0&id=80c14d50&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80c14d50",
  null
  
)

export default component.exports