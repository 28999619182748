
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["mdiClose"]),
  },
})
export default class Dialog extends Vue {
  data(): { dialog: boolean } {
    return {
      dialog: false,
    };
  }
}
