
import { Component, Vue } from "vue-property-decorator";

interface Data {
  count: number;
}

@Component
export default class SearchLoading extends Vue {
  interval!: number;
  count!: number;

  data(): Data {
    return {
      count: this.count || 0,
    };
  }

  created(): void {
    this.interval = setInterval(() => {
      this.count = ++this.count % 4;
    }, 350);
  }
}
