
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import SearchHelpPopup from "./SearchHelpPopup.vue";

interface Data {
  open: boolean;
}

@Component({
  components: {
    SearchHelpPopup,
  },
  computed: {
    ...mapGetters(["mdiClose"]),
  },
})
export default class SearchHelp extends Vue {
  data(): Data {
    return {
      open: true,
    };
  }
}
