
import { Component, Vue } from "vue-property-decorator";
import { INavLink } from "@/interfaces/nav-link.interface";
import { headerLinks } from "@/data/nav";

interface Data {
  drawer: boolean;
  links: INavLink[];
}

@Component
export default class Header extends Vue {
  data(): Data {
    return {
      drawer: false,
      links: headerLinks || [],
    };
  }
}
