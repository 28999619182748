var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-app-bar',{staticClass:"app-bar",attrs:{"app":"","flat":""}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{class:{
            'pl-1': _vm.$vuetify.breakpoint.smAndDown,
            'pl-5': _vm.$vuetify.breakpoint.mdAndUp,
            'd-flex align-center': true,
          }},[_c('v-toolbar-title',{staticClass:"app-bar-title",attrs:{"router-link":"","to":"/search"}},[_c('router-link',{attrs:{"to":"/search"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo.svg"),"alt":"British Columbia","width":"48px","height":"42px"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo-banner.svg"),"alt":"British Columbia","width":"172px","height":"48px"}}):_vm._e()]),_c('span',{class:{
                'font-weight-black d-inline-flex align-middle': true,
                'pl-4': _vm.$vuetify.breakpoint.smAndDown,
                'pl-8': _vm.$vuetify.breakpoint.mdAndUp,
              }},[_c('span',[_vm._v("OrgBook BC")])])],1)],1),_c('v-col',{staticClass:"pa-0 d-flex justify-end align-center"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',_vm._l((_vm.links),function(link){return _c('v-btn',{key:link.path,staticClass:"text-capitalize",attrs:{"plain":"","text":"","color":"white","to":{ name: link.name },"ripple":false}},[_vm._v(_vm._s(link.label))])}),1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{staticClass:"mr-n2 app-bar-nav-icon",attrs:{"color":"white","aria-label":"nav-menu-button"},on:{"click":function($event){_vm.drawer = true}}}):_vm._e()],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},_vm._l((_vm.links),function(link){return _c('v-list-item',{key:link.path,attrs:{"to":link.path}},[_vm._v(_vm._s(link.label))])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }