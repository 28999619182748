
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import SearchBar from "@/components/search/SearchBar.vue";
import SearchDescription from "@/components/search/SearchDescription.vue";
import SearchHelp from "@/components/search/SearchHelp.vue";
import SearchHome from "@/components/search/SearchHome.vue";
import SearchLoading from "@/components/search/SearchLoading.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { defaultQuery } from "@/utils/search";
import { ISearchQuery } from "@/interfaces/api/v4/search-topic.interface";
import store from "@/store";

interface Data {
  q: string | null;
}

Component.registerHooks(["beforeRouteEnter", "beforeRouteUpdate"]);

@Component({
  components: {
    SearchBar,
    SearchDescription,
    SearchHelp,
    SearchHome,
    SearchLoading,
    SearchResult: () =>
      import(
        /* webpackChunkName: "search-result" */ "@/components/search/SearchResult.vue"
      ),
  },
  computed: {
    ...mapGetters(["loading", "searchQuery", "pagedSearchTopics"]),
  },
  methods: {
    ...mapActions([
      "setLoading",
      "fetchCredentialTypes",
      "fetchSearchFacetedTopics",
      "resetSearch",
    ]),
  },
})
export default class Search extends Vue {
  q!: unknown;

  setLoading!: (loading: boolean) => void;
  fetchCredentialTypes!: (paged: boolean) => Promise<void>;
  fetchSearchFacetedTopics!: () => Promise<void>;
  resetSearch!: () => void;

  data(): Data {
    return {
      q: null,
    };
  }

  async created(): Promise<void> {
    const query = this.$route.query as unknown as ISearchQuery;
    if (query?.q || query?.credential_type_id) {
      await this.extractQueryAndDispatchSearch(query);
    } else {
      this.resetSearch();
    }
  }

  /**
   * This is executed when a filter is (de)selected. The state managed search query and filters
   * are updated in the store, which in turn active an in-place route change. The route change is
   * what signals fethcing of search results.
   * */
  async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: NavigationGuardNext<Vue>
  ): Promise<void> {
    // Need to call next first otherwise the URL is updated after the search completes
    next();
    const query = this.$route.query as unknown as ISearchQuery;
    if (query?.q || query?.credential_type_id) {
      await this.extractQueryAndDispatchSearch(query);
    } else {
      this.resetSearch();
    }
  }

  async extractQueryAndDispatchSearch(query: ISearchQuery): Promise<void> {
    this.q = query?.q || null;
    const newQuery = { ...defaultQuery, ...query };
    store.dispatch("setSearchQuery", newQuery);
    store.dispatch("setSearchFilters", newQuery);
    await this.search();
  }

  async search(): Promise<void> {
    this.setLoading(true);
    await Promise.all([
      this.fetchCredentialTypes(false),
      this.fetchSearchFacetedTopics(),
    ]);
    this.setLoading(false);
  }
}
