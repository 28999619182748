var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-col',{class:{
        'pl-0 pr-0': true,
        'pr-4': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"cols":"12","md":"5"}},[_c('p',{staticClass:"text-h5 font-weight-bold"},[_vm._v("OrgBook BC")]),_c('div',{staticClass:"pb-2"},[_vm._v(" OrgBook BC provides verified data about organizations including: ")]),_c('ul',{staticClass:"pa-0 mb-2 description"},_vm._l((_vm.descriptions),function(description){return _c('li',{key:description,staticClass:"pl-4 pb-2"},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCheckBold))]),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(description))])],1)}),0),_c('div',[_c('v-icon',{staticClass:"icon-dense",attrs:{"dense":"","color":"black"}},[_vm._v(_vm._s(_vm.mdiShieldCheckOutline))]),_c('router-link',{staticClass:"vertical-align-middle",attrs:{"to":"/about/digital-trust"}},[_vm._v("Powered by digital trust")])],1)]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"id":"searchHomeGrapic","cols":"12","md":"4"}},[_c('img',{attrs:{"src":require("@/assets/img/graphic.png"),"alt":"","srcset":"","height":"300px"}})]):_vm._e(),_c('v-col',{class:{
        'pl-0 pr-0': true,
        'pl-4': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"id":"searchHomeStats","cols":"12","md":"3"}},[_c('p',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Current Statistics")]),(_vm.statistics)?_c('div',[_c('div',[_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.statistics.credential_counts.registrations))]),_vm._v(" active legal entities ")])]),_c('div',[_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.statistics.credential_counts.active))]),_vm._v(" verifiable credentials held ")])]),_c('div',[_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.statistics.credential_counts.last_week))]),_vm._v(" credentials added this week ")]),_c('p')])]):_vm._l(([1, 2, 3]),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"table-cell"}})})],2)],1),(_vm.showcaseLinks.length)?_c('ShowcaseLinks',{staticClass:"ml-n2 mr-n2"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }