
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { IFeedback } from "@/interfaces/api/v4/feedback.interface";
import { trackStructEvent } from "@snowplow/browser-tracker";

interface Data {
  reason: string;
  comments: string;
  improvements: string;
  submitted: boolean;
}

@Component({
  computed: {
    ...mapGetters(["mdiThumbDown", "mdiThumbUp", "getLikeStatus"]),
  },
  methods: {
    ...mapActions(["sendFeedback", "setLoading", "setLike"]),
  },
})
export default class Feedback extends Vue {
  reason!: "like" | "dislike" | "";
  comments!: string;
  improvements!: string;
  submitted!: boolean;
  getLikeStatus!: "like" | "dislike" | "";

  sendFeedback!: (feedback: IFeedback) => Promise<void>;
  setLoading!: (loading: boolean) => void;
  setLike!: (like: "like" | "dislike" | "") => void;

  data(): Data {
    return {
      reason: "",
      comments: "",
      improvements: "",
      submitted: false,
    };
  }

  like(): void {
    // trackStructEvent({
    //   category: "Feedback",
    //   action: "Submit",
    //   label: "Like",
    //   value: 0.0,
    // });
    this.setLike("like");
  }

  dislike(): void {
    // trackStructEvent({
    //   category: "Feedback",
    //   action: "Submit",
    //   label: "Dislike",
    //   value: 0.0,
    // });
    this.setLike("dislike");
  }

  get atContactPage(): boolean {
    return this.$route.path.includes("/contact");
  }

  async submit(): Promise<void> {
    this.setLoading(true);
    await this.sendFeedback({
      reason: this.reason,
      comments: this.comments,
      improvements: this.improvements,
    });
    window.sessionStorage.setItem("Feedback", "true");
    this.submitted = true;
    this.setLoading(false);
  }

  get isLike(): boolean {
    return this.reason === "like";
  }
}
