
import { Component, Vue } from "vue-property-decorator";
import { footerLinks } from "@/data/nav";
import { INavLink } from "@/interfaces/nav-link.interface";
import About from "@/components/layout/footer/About.vue";
import Feedback from "@/components/layout/footer/Feedback.vue";
import { mapGetters } from "vuex";

interface Data {
  links: INavLink[];
  fab: boolean;
}

@Component({
  components: {
    About,
    Feedback,
  },
  computed: {
    ...mapGetters(["mdiArrowUp"]),
  },
})
export default class Footer extends Vue {
  fab!: boolean;

  data(): Data {
    return {
      links: footerLinks || [],
      fab: false,
    };
  }

  get feedbackSubmitted(): boolean {
    return (
      window.sessionStorage.getItem("Feedback") !== null &&
      window.sessionStorage.getItem("Feedback") === "true"
    );
  }

  onScroll(): void {
    if (typeof window === "undefined") return;
    const top = window.pageYOffset || 0;
    this.fab = top > 20;
  }

  toTop(): void {
    this.$vuetify.goTo(0);
  }
}
