
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import ShowcaseLinks from "@/components/about/ShowcaseLinks.vue";

interface Data {
  descriptions: string[];
}

@Component({
  components: {
    ShowcaseLinks,
  },
  computed: {
    ...mapGetters([
      "statistics",
      "showcaseLinks",
      "mdiCheckBold",
      "mdiShieldCheckOutline",
    ]),
  },
  methods: {
    ...mapActions(["fetchStatistics"]),
  },
})
export default class SearchHome extends Vue {
  fetchStatistics!: () => Promise<void>;

  data(): Data {
    return {
      descriptions: [
        "Business numbers",
        "Legal names and DBA names",
        "BC registration status",
        "Select licenses and permits",
        "Some addresses",
      ],
    };
  }

  created(): void {
    this.fetchStatistics();
  }
}
