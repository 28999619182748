
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash-es";
import { IApiPagedResult } from "@/interfaces/api/result.interface";
import { ISearchAutocomplete } from "@/interfaces/api/v3/search-autocomplete.interface";
import { ISearchQuery } from "@/interfaces/api/v4/search-topic.interface";
import { defaultQuery } from "@/utils/search";
import SearchHelpPopup from "./SearchHelpPopup.vue";

interface Data {
  index: number;
  items: string[];
  q: string;
  pending: boolean;
}

@Component({
  components: {
    SearchHelpPopup,
  },
  computed: {
    ...mapGetters(["loading", "mdiMagnify", "mdiClose", "mdiHelpCircle"]),
  },
  methods: {
    ...mapActions(["fetchAutocomplete", "fetchSearch"]),
  },
})
export default class SearchBar extends Vue {
  loading!: boolean;
  index!: number;
  items!: string[];
  q!: string;
  pending!: boolean;

  setLoading!: (loading: boolean) => void;
  fetchAutocomplete!: (
    query: string
  ) => Promise<IApiPagedResult<ISearchAutocomplete>>;
  fetchSearch!: (query: ISearchQuery) => void;

  debouncedAutocomplete = _.debounce(this.autocomplete, 500);

  @Prop({ default: "" }) query!: string;

  data(): Data {
    return {
      index: -1,
      items: [],
      q: this.query || "",
      pending: false,
    };
  }

  async autocomplete(q: string): Promise<void> {
    try {
      this.pending = true;
      const response = await this.fetchAutocomplete(q);
      this.items = (response?.results || [])
        .map((result) => result.value)
        .filter(
          (result) =>
            (result || "").toLowerCase().indexOf((result || "").toLowerCase()) >
            -1
        );
    } catch (e) {
      console.error(e);
    } finally {
      this.pending = false;
    }
  }

  autocompleteSearch(val: string): void {
    this.escapeSearch();
    if (!val || this.loading) {
      return;
    }
    this.debouncedAutocomplete(val);
  }

  changeSearch(q: string): void {
    this.q = q;
    this.executeSearch(q);
  }

  escapeSearch(): void {
    this.resetIndex();
    this.resetAutocomplete();
  }

  enterSearch(): void {
    if (this.index >= 0) {
      this.q = this.items[this.index];
    }
    this.submitSearch();
  }

  arrowDown(): void {
    if (this.index < this.items.length - 1) {
      this.index += 1;
    } else {
      this.index = 0;
    }
  }

  arrowUp(): void {
    if (this.index > 0) {
      this.index -= 1;
    } else {
      this.index = this.items.length - 1;
    }
  }

  submitSearch(): void {
    if (!this.q) {
      return;
    }
    this.executeSearch(this.q);
  }

  private executeSearch(q: string) {
    this.escapeSearch();
    const query = { ...defaultQuery, ...{ q } };
    this.fetchSearch(query);
  }

  private resetIndex(): void {
    this.index = -1;
  }

  private resetItems(): void {
    this.items = [] as string[];
  }

  private resetAutocomplete(): void {
    this.debouncedAutocomplete.cancel();
    this.resetItems();
  }
}
