
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["showcaseLinks"]),
  },
})
export default class ShowcaseLinks extends Vue {}
