
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Dialog from "@/components/shared/Dialog.vue";

@Component({
  components: {
    Dialog,
  },
  computed: {
    ...mapGetters(["mdiHelpCircleOutline"]),
  },
})
export default class SearchHelpPopup extends Vue {
  @Prop({ default: false }) light!: boolean;
}
