
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

interface Data {
  descriptions: string[];
}

@Component({
  computed: {
    ...mapGetters(["mdiCheckBold"]),
  },
})
export default class SearchDescription extends Vue {
  data(): Data {
    return {
      descriptions: [
        "Organization name",
        "Business number",
        "BC Registries ID",
      ],
    };
  }
}
